//import React, { startTransition } from 'react'
import React from 'react'
//import { Helmet } from 'react-helmet';

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Autoplay,
  Navigation,
  Pagination,
} from "swiper";
import "swiper/css/bundle";
import p1 from '../assets/pictures/MoteofMark1.jpg'; 
import p2 from '../assets/pictures/NextToCaravanSite.jpeg'; 
import p3 from '../assets/pictures/glenstocken.jpeg'; 
import p4 from '../assets/pictures/fromtentarea.jpg'; 




export default function Statics() {

  SwiperCore.use([Autoplay, Navigation, Pagination]);

  const arrayOfImages=[p1,p2,p3,p4]
  return (
    <>

<Swiper
            slidesPerView={1}
            navigation
            pagination={{ type: "progressbar" }}
            effect="fade"
            modules={[EffectFade]}
            autoplay={{ delay: 3000 }}
          >
            {arrayOfImages.map((url, index) => (
              <SwiperSlide key={index}>
                <div 
                
                className=" relative w-full overflow-hidden h-[300px] " 
                  style={{
                    background: `url(${arrayOfImages[index]})  center no-repeat`,
                    backgroundSize: "cover ",
                  }}
                ></div>
              </SwiperSlide>
            ))}
          </Swiper>
    <div  class="flex flex-col md:flex-row md:max-w-x3 rounded-lg bg-white shadow-lg">

<div class="p-6 flex flex-col justify-start">
  <h5 class="font-black text-xl font-medium mb-2">Check Availability and book a Holiday Home Caravan  -  2025 </h5>
  <p class="text-gray-700 text-base mb-4">
  All hire caravans have sea views, are double glazed, have extra insulation installed by the manufacturer, balconies with tables and chairs. Some caravans are designated pet friendly and some pet free.
  </p>
 
  <p class="text-gray-700 text-base mb-4">
  Type A caravans have central heating and French doors leading to the balcony.   
  Full bedding and make up of the beds can be provided at £12 per bed (sheets, duvet and pillows). Customers can bring their own bedding if wished. 
  Mattress covers are always provided.
  A hand towel and bath towel can be hired for £2.50 per set for the duration of the stay. The price is £70 per night.
  </p>
  <p class="text-gray-700 text-base mb-4">
  Type A+ are as Type A but with residential specification insulation and come with welcome pack and towel and bedding service.
 The price is £87 per night</p>
  <p class="text-gray-700 text-base mb-4">
  Caravans are usually hired  from  4.00 p.m. until  10 a.m. on the leaving day unless by arrangement. 
  However, some may also be hired for short breaks, with a minimum of three days. 
  </p>
  <p class="text-gray-700 text-base mb-4">
  The base price is for two people. If there are more than two people please add £6 per additional person over 16 per night and £3.00 per night for each additional child over 4. Children under 5 are free. There is a supplementary charge of £6.00 per night per pet if pets are brought. No more than one please unless by special arrangement.
      </p>
      <p class="text-gray-700 text-base mb-4">
      While the caravan can accommodate 6 people they are more suited to 4. 
      There are two bedrooms, one with a double and the other with two singles. 
      The lounge area has a fold out double bed. Note that Cairnsmore (an A caravan) is different with 2 double beds and a double sofa bed
      </p>

      <p class="text-gray-700 text-base mb-4">The A+ caravans can only be booked Saturday to Saturday. 
        The A caravans have a minimum stay of 3 days. 
        The term "Restrictions apply" on the calendar refers to these constraints.
        Hire caravans will not appear as a booking option if the constraints on the dates  exclude them.</p>

      <p class="text-green-700 text-base mb-8">
      Select an arrival date and a leaving date or use the calendar to pick a start date. 
       
      
      </p>


      <p class="text-gray-700 text-xs display: inline">
      The initial deposit is not refundable.  Cancellations to bookings more than 14 days  prior to arrival are without 
      further charge. <b> Full payment is due 14 days prior to arrival.</b> Failure to make balance payments on time means your accommodation will be offered for re-letting.  All cancellations prior to 14 days must be confirmed by email to be registered.

Changes to bookings more than 14 days prior to arrival are without further charge except where 
the revised booking costs is more than the previous booking, when the balance of the increased 
deposit should be paid. The deposit is 15%.
      </p>


</div>



</div>
</>



)


}


/* 
<div id="qbook_widget" class="p-6 flex flex-col justify-start">
<Helmet>

  <script src="https://secure.hotels.uk.com/js/widgets.js" data-qbook="49345" data-token="383c25466be708e0ee9b23cabe4304f3"></script>
  </Helmet>
  </div>
this works */ 
